import styled from 'styled-components';

export const DocumentSt = styled.section`
  padding: 30px 0 20px 0;
  position: relative;
  height: 100%;
`;

export const DocumentRequest = styled.section`
  display: flex;
  flex-direction: column;

  h3{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
    margin-bottom: 10px;
  }

  .scroll{
    max-width: 1200px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 0.5rem;
    overflow: hidden;
    background-color: ${(props) => props.theme.backgroundHover};

    > button{
      display: flex;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
      flex: 0 0 auto;
      border: 0;
      font-size: 18px;
      margin-left: 20px;
      color: ${(props) => props.theme.textDark};

      &:nth-child(1){
        margin-right: 20px;
        margin-left: 0px;
      }
    }
  }
`;

export const DocumentSelection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    padding: 10px 20px;
    min-width: 230px;
    border: 0;
    margin-right: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.textDark};
    /* background-color: ${(props) => props.theme.background}; */

    span{
      font-size: 16px;
    }

    svg{
      margin-right: 5px;
      font-size: 18px;
    }
  }

  &::-webkit-scrollbar{
    display: none;
  }
`;

export const DocumentGroupInput = styled.div`
  width: 100%;
  margin-top: 25px;

  .input_select{
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);

    button{
      svg{
        color: ${(props) => props.theme.layout} !important;
      }
    }
  }

`;

export const DocumentInput = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 0.5rem;
  margin-top: 15px;
  background-color: ${(props) => props.theme.backgroundHover};
  cursor: pointer;

  svg{
    font-size: 20px;
  }

  span{
    font-size: 16px;
    margin-left: 7px;
  }

  button{
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    margin-left: auto;

    svg{
      color: ${(props) => props.theme.textDark};
    }
  }
`;

export const DocumentSend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  border-radius: 0.5rem;
  padding: 20px 25px 30px 25px;
  bottom: 0;
  left: 0;

  span{
    display: flex;
    align-items: center;
    font-size: 16px;

    b{
      font-size: 18px;
      margin-left: 5px;
    }
  }

  a{
    width: 100%;
    max-width: 230px;
    font-size: 16px;
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};
    padding: 12px 5px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;
