import React, { useEffect, useRef, useState } from 'react';
import { TiDocumentText } from 'react-icons/ti';
import { GrDocumentUser, GrRadialSelected } from 'react-icons/gr';
import { TbHammer } from 'react-icons/tb';
import { BiHomeAlt2 } from 'react-icons/bi';
import { IoNewspaperOutline } from 'react-icons/io5';
import { GiBrazil } from 'react-icons/gi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import * as styles from './style';
import useOrder from '../../store/Order';

export default function Documents() {
  const [orderFilter, setOrderFilter] = useState([]);
  const [orderFilterOption, setOrderFilterOption] = useState('');
  const {
    fetchOrders, order, setOrderSelect, orderSelect,
  } = useOrder();

  const scrollRef = useRef(null);

  const operations = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Registro Civil', icon: <GrDocumentUser />, value: 'registro-civil' },
    { name: 'Registro de Imóveis', icon: <BiHomeAlt2 size="20px" />, value: 'registro-de-imoveis' },
    { name: 'Notas', icon: <IoNewspaperOutline />, value: 'notas' },
    { name: 'Protesto', icon: <TbHammer />, value: 'protesto' },
    { name: 'Federais', icon: <GiBrazil />, value: 'federais' },
    { name: 'Estaduais', icon: <GiBrazil />, value: 'estaduais' },
    { name: 'Municipais', icon: <GiBrazil />, value: 'municipais' },
  ];

  useEffect(() => {
    fetchOrders();
    setOrderSelect([]);
  }, []);

  useEffect(() => {
    if (order) {
      console.log(order);
      const orderFilterList = order.filter((od) => od.type === 'order');

      setOrderFilter(orderFilterList);
    }

    if (orderFilterOption) {
      const orderFilterList = order.filter((od) => od.type === 'order');
      const orderFilterListOption = orderFilterList.filter((od) => od.selectedOption === orderFilterOption);

      setOrderFilter(orderFilterListOption);
    }
  }, [order, orderFilterOption]);

  const handleOrder = (id) => {
    console.log(orderSelect);
    const isSelected = orderSelect.filter((od) => od.id === id);

    if (isSelected) {
      if (isSelected.length > 0) {
        const updatedOrderSelect = orderSelect.filter((od) => od.id !== id);

        console.log(updatedOrderSelect);
        setOrderSelect(updatedOrderSelect);
      } else {
        const orderId = orderFilter.filter((od) => od.id === id);

        const dataOrder = orderSelect.map((od) => od);

        console.log(dataOrder);
        dataOrder.push(orderId[0]);
        console.log(dataOrder);

        if (orderId) {
          setOrderSelect(dataOrder);
        }
      }
    }
  };

  const searchIcon = (icon) => {
    switch (icon) {
      case 'registro-civil':
        return <GrDocumentUser />;
      case 'notas':
        return <IoNewspaperOutline />;
      case 'registro-de-imoveis':
        return <BiHomeAlt2 />;
      case 'protesto':
        return <TbHammer />;
      case 'federais':
        return <GiBrazil />;
      case 'estaduais':
        return <GiBrazil />;
      case 'municipais':
        return <GiBrazil />;
      default:
        return null;
    }
  };

  const isSelected = (id) => (orderSelect ? orderSelect.some((od) => od.id === id) : false);

  const scrollLeft = () => {
    if (scrollRef.current) {
      console.log(scrollRef.current.scrollBy());
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <styles.DocumentSt>
          <Title text="Solicitar um documento" url="requestdocument" icon={<TiDocumentText />} />

          <styles.DocumentRequest>
            <h3>Selecione qual documento deseja</h3>

            <div className="scroll">
              <button type="button" onClick={scrollLeft}><FaArrowLeft /></button>

              <styles.DocumentSelection ref={scrollRef}>
                {operations.map((op) => (
                  <button type="button" onClick={() => setOrderFilterOption(op.value)}>
                    {op.icon}
                    <span>{op.name}</span>
                  </button>
                ))}

              </styles.DocumentSelection>

              <button type="button" onClick={scrollRight}><FaArrowRight /></button>
            </div>

            <styles.DocumentGroupInput>

              {orderFilter && (

                orderFilter.map((od) => (
                  <styles.DocumentInput
                    id={od.id}
                    onClick={() => handleOrder(od.id)}
                    className={isSelected(od.id) ? 'input_select' : ''}
                  >
                    {searchIcon(od.selectedOption)}
                    <span>{od.name}</span>

                    <button type="button">
                      <GrRadialSelected />
                    </button>
                  </styles.DocumentInput>
                ))

              )}

            </styles.DocumentGroupInput>

          </styles.DocumentRequest>

          <styles.DocumentSend className="box_shadow">
            <span>
              Documentos selecionados:
              {' '}
              {orderSelect && (
                <b>{orderSelect.length}</b>
              )}
            </span>

            <Link to="/form">
              Continuar
            </Link>
          </styles.DocumentSend>

        </styles.DocumentSt>
      </Center>
    </article>
  );
}
