import React, { useEffect, useState } from 'react';
import { IoClose, IoDocumentsOutline } from 'react-icons/io5';
import { FaFileDownload } from 'react-icons/fa';
import * as styles from './style';
import useApplication from '../../store/Application';
import { KitsClear } from '../../pages/Kits/style';

export default function OrderInfo({ func, uid }) {
  // const [thisOrder, setThisOrder] = useState({});
  const [thisAppl, setThisAppl] = useState('');

  const { application } = useApplication();

  useEffect(() => {
    function fetchOrder() {
      const appl = application.filter((od) => od.id === uid);

      console.log(appl[0]);
      setThisAppl(appl);
    }
    fetchOrder();
  }, [application]);

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Detalhes do seu pedido</h2>

        {thisAppl[0] && (
          <h3>{thisAppl[0].nameOrder}</h3>
        )}

        {thisAppl[0] && thisAppl[0].orderSelect && (
          thisAppl[0].orderSelect.length > 0 ? (
            thisAppl[0].orderSelect.map((appl) => (
              <styles.ApplicationList key={appl.name}>
                <span>{appl.name}</span>
                <span>
                  R$
                  {' '}
                  {parseInt(appl.prince).toFixed(2).replace('.', ',')}
                </span>
                <span className={appl.file ? 'table_access_true' : 'table_access_false'}>{appl.file ? 'Finalizado' : 'Pendente'}</span>
                <a href={appl.file} target="blank" rel="noreferrer">
                  <FaFileDownload />
                </a>
              </styles.ApplicationList>
            ))
          ) : (
            <KitsClear>
              <IoDocumentsOutline />
              <h3>Ainda não tem nada aqui</h3>
              <span>O seu pedido está pendente ou em processo! Aguarde que logo chegará</span>
            </KitsClear>
          )
        )}

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
