import styled from 'styled-components';

export const RechargeSt = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;

  background-color: ${(props) => props.theme.backgroundHover};
  padding: 20px 15px 30px 15px;
  color: ${(props) => props.theme.text};
  border-radius: 0.5rem;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

  h2{

    
    &:nth-child(1){
      margin-top: 0;
    }
    
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;

    svg{
      font-size: 22px;
      margin-right: 5px;
    }
  }

  p{
    margin-top: 10px;
    font-size: 30px;
    font-weight: 300;
    color: #00BD2C;
  }

  span{
    font-weight: 300;
    font-size: 14px;
    margin-top: 5px;
    color: ${(props) => props.theme.textDark};
  }

`;

export const RechargeButton = styled.a`
  background-color: ${(props) => props.theme.layout};
  color: ${(props) => props.theme.textLigth};
  padding: 14px;
  margin-top: 25px;
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  font-size: 18px;

  &:hover{
    transition: 0.7s;
    background-color: ${(props) => props.theme.layoutHover};
  }
`;
