import React from 'react';
import { IoDocumentsOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';

export default function Kits() {
  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Meus kits" url="kits" icon={<IoDocumentsOutline />} />

          <styles.KitsSt>
            <styles.KitsButton>
              <Link to="/">
                <FaPlus />
                Criar kit
              </Link>
            </styles.KitsButton>

            <styles.KitsClear>
              <IoDocumentsOutline />
              <h3>Ainda não há kits criados</h3>
              <span>Kits são formas inteligentes de criar um pedido composto de documentos. Comece agora mesmo.</span>
            </styles.KitsClear>
          </styles.KitsSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
