import { create } from 'zustand';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../service/firebaseConnection';

const useOrder = create((set) => ({
  orders: [],

  setOrderSelect: (order = []) => {
    set({ orderSelect: order });
  },

  setOrderCart: (order = []) => {
    set({ orderCart: order });
  },

  fetchOrders: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'orders'));

      const ordersList = [];

      querySnapshot.forEach((doc) => {
        ordersList.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      set({ order: ordersList });
    } catch (error) {
      console.log(error);
    }
  },
}));

export default useOrder;
