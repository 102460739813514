// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyACZgfCPjO9ExdwkJ--VuF3F8hUKDIKNrc',
  authDomain: 'jk-sistemas.firebaseapp.com',
  projectId: 'jk-sistemas',
  storageBucket: 'jk-sistemas.appspot.com',
  messagingSenderId: '87711990391',
  appId: '1:87711990391:web:b9efb8edc5ab031c657c72',
  measurementId: 'G-LTBTLCK272',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
