import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html, body, #root{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${(props) => props.theme.background};
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 400;
    color: ${(props) => props.theme.textDark};
  }

  a{
    color: inherit;
    text-decoration: none;
  }

  .article_flex{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: row;
  }
  
  .box_shadow{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  }
`;
