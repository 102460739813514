import React, { useState } from 'react';
import { FaPencil } from 'react-icons/fa6';
import { IMaskInput } from 'react-imask';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import useOrder from '../../store/Order';
import useUser from '../../store/User';

export default function Form() {
  const [verif, setVerif] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [state, setState] = useState('');
  const [name, setName] = useState('');
  const [nameOrder, setNameOrder] = useState('');

  const { orderSelect, setOrderCart, orderCart } = useOrder();
  const { user } = useUser();
  const navigate = useNavigate();

  console.log(orderSelect);

  const handleSubmit = (e) => {
    e.preventDefault();

    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = today.getFullYear();

    const formatDate = `${day}/${month}/${year}`;

    if (verif) {
      if (name !== '' && cpf !== '' && state !== '') {
        const data = {
          id: orderCart ? orderCart.length + 1 : 1,
          userUid: user.uid,
          userDoc: user.cpf || user.cnpj,
          date: today,
          dateFormat: formatDate,
          name,
          nameOrder,
          state,
          cpf,
          orderSelect,
        };

        const listCart = orderCart || [];

        listCart.push(data);

        console.log(listCart);

        setOrderCart(listCart);

        navigate('/pending');
      } else {
        alert('oi');
      }
    } else if (name !== '' && cnpj !== '' && state !== '') {
      const data = {
        id: orderCart ? orderCart.length + 1 : 1,
        userUid: user.uid,
        userDoc: user.cpf || user.cnpj,
        date: today,
        dateFormat: formatDate,
        name,
        nameOrder,
        state,
        cpf,
        orderSelect,
      };

      const listCart = orderCart || [];

      listCart.push(data);

      console.log(listCart);

      setOrderCart(listCart);

      navigate('/pending');
    } else {
      alert('oi');
    }
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>

          <Title text="Preencha os dados do pedido" url="form" icon={<FaPencil />} />
          <styles.FormSt>
            <h3>Preencha os dados</h3>

            <styles.FormFlex>

              <styles.FormGroup>

                <styles.FormInput>
                  <span>Estado do documento</span>
                  <select value={state} onChange={(e) => setState(e.target.value)}>
                    <option selected>Todos os estados</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </styles.FormInput>

                <h4>Documentos da certidão</h4>

                <styles.FormVerif $isVerif={verif}>
                  <button type="button" onClick={() => setVerif(true)}>Cpf</button>
                  <button type="button" onClick={() => setVerif(false)}>Cnpj</button>
                </styles.FormVerif>

                {verif && (
                <styles.FormInput>
                  <span>CPF</span>
                  <IMaskInput mask="000.000.000-00" type="text" name="cpf" placeholder="Digite seu CPF" value={cpf} onChange={(e) => setCpf(e.target.value)} />

                  <span>Nome completo</span>
                  <input type="text" name="name" placeholder="Digite o nome completo" value={name} onChange={(e) => setName(e.target.value)} />
                </styles.FormInput>
                )}

                {!verif && (
                <styles.FormInput>
                  <span>CNPJ</span>
                  <IMaskInput mask="00.000.000/0000-00" type="text" name="cnpj" placeholder="Digite seu CNPJ" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />

                  <span>Nome da empresa</span>
                  <input type="text" name="name" placeholder="Digite o nome completo" value={name} onChange={(e) => setName(e.target.value)} />
                </styles.FormInput>
                )}

                <styles.FormInput>
                  <span>Nome do pedido</span>
                  <input type="text" name="order" placeholder="Digite o nome do pedido" value={nameOrder} onChange={(e) => setNameOrder(e.target.value)} />
                </styles.FormInput>

                <button type="submit" className="send_form" onClick={handleSubmit}>Enviar</button>

              </styles.FormGroup>

              <styles.FormInfos>
                <h4>Dados do pedido</h4>

                <styles.FormInfosList>
                  <ul>
                    {orderSelect.map((od) => (
                      <li>{od.name}</li>
                    ))}
                  </ul>

                  <h5>
                    Nome do pedido:
                    {' '}
                    <b>{nameOrder}</b>
                  </h5>

                  <p>
                    <div>
                      <FaRegCalendarAlt />
                      {' '}
                      Prazo Estimado:
                      {' '}
                    </div>

                    <span>
                      de
                      {' '}
                      <b>1</b>
                      {' '}
                      a
                      {' '}
                      <b>2</b>
                      {' '}
                      dias úteis
                    </span>
                  </p>

                  <span>
                    Valor do pedido:
                    <h6>
                      R$
                      {' '}
                      {orderSelect && (
                        orderSelect.reduce((total, item) => total + parseFloat(item.prince), 0).toFixed(2).replace('.', ',')
                      )}
                    </h6>
                  </span>
                </styles.FormInfosList>

              </styles.FormInfos>

            </styles.FormFlex>

          </styles.FormSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
