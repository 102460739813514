import React from 'react';
import { MdOutlineAttachMoney } from 'react-icons/md';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import Recharge from '../../components/Recharge';

export default function Financial() {
  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Financeiro" url="financial" icon={<MdOutlineAttachMoney />} />

          <styles.FinancialSt>
            <h3>Coloque créditos</h3>

            <styles.RechargeGroup>
              <Recharge money={100} recharge={100} bonus={0} />
              <Recharge money={200} recharge={205} bonus={5} />
              <Recharge money={500} recharge={520} bonus={20} />
              <Recharge money={1000} recharge={1050} bonus={50} />
            </styles.RechargeGroup>
          </styles.FinancialSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
