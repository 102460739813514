import styled from 'styled-components';

export const MyRequestOperations = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const MyRequestOperation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  /* background-color: ${(props) => props.theme.backgroundHover}; */
  padding: 12px 20px;
  border-radius: 0.5rem;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

  p{
    font-size: 18px;
    font-weight: 400;
    color: #F5190A;
    color: ${(props) => (props.$isYellow ? '#D7A800' : '')};
    color: ${(props) => (props.$isGreen ? '#00BD2C' : '')};
  }

  h2{
    font-size: 22px;
    font-weight: 600;
  }
`;

export const MyRequestTables = styled.table`
  width: 100%;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    text-align: center;
    font-weight: 300;

    &:nth-child(3){
      font-weight: 600;
    }

    &:nth-child(5){
      svg{
        cursor: pointer;
        color: ${(props) => props.theme.layout};
      }
    }
  }

  span{
    padding: 5px 8px;
    border-radius: 0.5rem;
    color: ${(props) => props.theme.textLigth};
  }

  .table_access_false{
    background-color: #F5190A;
  }

  .table_access_true{
    background-color: #01F510;
  }
  
  .table_access_progress{
    background-color: #D7A800;
  }

  .icon_access_false{
    color: #F5190A !important;
  }

  .icon_access_true{
    color: #01F510 !important;
  }
  
  .icon_access_progress{
    color: #D7A800 !important;
  }
`;
