import React from 'react';
import axios from 'axios';

function PaymentButton({ title, price, quantity }) {
  const handlePayment = async () => {
    try {
      const response = await axios.post('http://localhost:4000/create_preference', {
        title,
        price,
        quantity,
      });

      const preferenceId = response.data.id;

      const existingScript = document.querySelector(`script[data-preference-id="${preferenceId}"]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js';
        script.setAttribute('data-preference-id', preferenceId);
        document.body.appendChild(script);
      }
    } catch (error) {
      console.error('Erro ao criar a preferência de pagamento', error);
      alert('Erro ao criar a preferência de pagamento. Por favor, tente novamente.');
    }
  };

  return (
    <button type="button" onClick={handlePayment}>
      Pagar com MercadoPago
    </button>
  );
}

export default PaymentButton;
