import React from 'react';
import { FaPencil, FaPowerOff } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { GoFileDirectory } from 'react-icons/go';
import { TbSettings } from 'react-icons/tb';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { IoDocumentsOutline } from 'react-icons/io5';
import * as styles from './style';
import img from '../../assets/jk-logo.png';
import useUser from '../../store/User';

export default function Header() {
  const links = [
    { text: 'Meus pedidos', href: '/myrequest', icon: <GoFileDirectory /> },
    { text: 'Meus kits', href: '/kits', icon: <IoDocumentsOutline /> },
    { text: 'Financeiro', href: '/financial', icon: <MdOutlineAttachMoney /> },
    { text: 'Ajuda', href: '/help', icon: <IoMdHelpCircleOutline /> },
    { text: 'Configurações', href: '/', icon: <TbSettings /> },
  ];

  const { logoutUser } = useUser();

  return (
    <styles.HeaderSt>
      <Link to="/">
        <img src={img} alt="logo" />
      </Link>

      <styles.HeaderRequest>
        <Link to="/request">
          <FaPencil />
          <span>Fazer pedido</span>
        </Link>
      </styles.HeaderRequest>

      <styles.HeaderUl>
        {links.map((link, index) => (
          <li id={index}>
            <Link to={link.href}>
              {link.icon}
              {' '}
              {link.text}
            </Link>
          </li>
        ))}
      </styles.HeaderUl>

      <button onClick={logoutUser} type="button">
        <FaPowerOff />
        {' '}
        <span>Sair</span>
      </button>
    </styles.HeaderSt>
  );
}
