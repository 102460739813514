import React, { useEffect, useState } from 'react';
import { GoFileDirectory } from 'react-icons/go';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { DashboardSt } from '../Dashboard/style';
import * as styles from './style';
import useApplication from '../../store/Application';
import useUser from '../../store/User';
import OrderInfo from '../../components/OrderInfo';

export default function MyRequest() {
  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const { user } = useUser();
  const { fetchApplication, application } = useApplication();

  useEffect(() => {
    fetchApplication(user.uid);
  }, []);

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  const changeIcon = (status) => {
    switch (status) {
      case 'pending':
        return 'icon_access_false';

      case 'progress':
        return 'icon_access_progress';

      default:
        return 'icon_access_true';
    }
  };

  console.log(application);

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Meus pedidos" url="myrequest" icon={<GoFileDirectory />} />

          <styles.MyRequestOperations>
            <styles.MyRequestOperation $isYellow>
              <p>Processando</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'progress' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>

            <styles.MyRequestOperation $isGreen>
              <p>Finalizados</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'finish' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>

            <styles.MyRequestOperation>
              <p>Pendentes</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'pending' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>
          </styles.MyRequestOperations>

          {application && (
            application.length > 0 && (
              <styles.MyRequestTables>
                <thead>
                  <tr>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>

                  {application.map((apl) => (
                    <tr>
                      <td>{apl.nameOrder}</td>
                      <td>{apl.dateFormat}</td>
                      <td>{parseFloat(apl.prince).toFixed(2).replace('.', ',')}</td>
                      <td>
                        <span className={changeCheck(apl.status)}>
                          {apl.status === 'pending' ? 'Pendente' : ''}
                          {apl.status === 'progress' ? 'Em andamento' : ''}
                          {apl.status === 'finish' ? 'Finalizado' : ''}
                        </span>
                      </td>
                      <td aria-hidden="true"><HiOutlineDocumentReport className={changeIcon(apl.status)} size={26} onClick={() => { setShow(true); setId(apl.id); }} /></td>
                    </tr>
                  ))}

                </tbody>
              </styles.MyRequestTables>
            )
          )}

          {show && (
            <OrderInfo func={() => setShow(false)} uid={id} />
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
