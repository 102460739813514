import styled from 'styled-components';

export const FinancialSt = styled.section`
  display: flex;
  flex-direction: column;

  h3{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
    margin-bottom: 10px;
  }
`;

export const RechargeGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
