import React from 'react';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import * as styles from './style';

export default function Recharge({ money, recharge, bonus }) {
  return (
    <styles.RechargeSt>
      <h2>
        <FaRegMoneyBillAlt />
        Recarregue
      </h2>

      <p>
        R$
        {' '}
        {money}
      </p>

      <h2>
        <GiReceiveMoney />
        Receba
      </h2>

      <p>
        R$
        {' '}
        {recharge}
      </p>

      <span>
        Incluso R$
        {' '}
        {bonus}
        {' '}
        de bônus
      </span>

      <styles.RechargeButton>
        Comprar créditos
      </styles.RechargeButton>
    </styles.RechargeSt>
  );
}
