import styled from 'styled-components';

export const DashboardSt = styled.section`
  padding: 30px 0 20px 0;
`;

export const DashboardText = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 20px;
  border-radius: 0.5rem;
  max-width: 600px;
  margin-top: 20px;
  color: ${(props) => props.theme.text};
  background-image: url(${(props) => props.$isBanner});
  background-position: 100% 100%;
  background-size: cover;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

  h2{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 400;

    img{
      width: 100%;
      max-width: 40px;
      margin-left: 10px;
    }
  }
`;

export const DashboardTextInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  div{
    display: flex;
    flex-direction: column;

    &:nth-child(1){
      padding-right: 40px;
      margin-right: 30px;
      border-right: 1px solid ${(props) => props.theme.textDark};
    }

    h3{
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.1rem;
      color: ${(props) => props.theme.layout};
    }

    span{
      font-size: 14px;
      margin-top: 2px;
      font-weight: 300;
    }
  }
`;

export const DashboardRequests = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 20px;
  border-radius: 0.5rem;
  margin-top: 20px;
  color: ${(props) => props.theme.text};

  h2{
    font-size: 20px;
    font-weight: 400;
  }

  div{
    margin-top: 10px;
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
  }
`;

export const DashboardAccess = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h3{
    font-size: 20px;
    font-weight: 400;
  }

  h4{
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
  }
`;

export const DashboardAccessButton = styled.a`
  width: 100%;
  max-width: 320px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.5rem;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  background-color: ${(props) => props.theme.layout};
  color: ${(props) => props.theme.textLigth};

  &:hover{
    transition: 0.7s;
    background-color: ${(props) => props.theme.layoutHover};
  }
`;
