import React, { useEffect, useState } from 'react';
import {
  FaCartShopping, FaInfo, FaPencil, FaPlus,
} from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import Title from '../../components/Title';
import Center from '../../components/Center';
import Header from '../../components/Header';
import useOrder from '../../store/Order';
import * as styles from './style';
import { DocumentSt } from '../Documents/style';
import useUser from '../../store/User';
import { db } from '../../service/firebaseConnection';
import PaymentButton from '../../components/Payment';

export default function Pending() {
  const [prince, setPrince] = useState(0);
  const { orderCart, setOrderCart } = useOrder();
  const { user } = useUser();

  const navigate = useNavigate('');

  useEffect(() => {
    console.log(orderCart);

    if (orderCart) {
      const totalPrince = orderCart.reduce((total, obj) => total + obj.orderSelect.reduce((sum, item) => sum + parseFloat(item.prince), 0), 0);

      setPrince(totalPrince);
    }
  }, [orderCart]);

  const handleDelete = (id) => {
    const orderId = orderCart.filter((od) => od.id !== id);

    setOrderCart(orderId);
  };

  const addOrders = async (e) => {
    e.preventDefault();

    const dataFormat = [];

    const querySnapshot = await getDocs(collection(db, 'application'));
    const existingCount = querySnapshot.size;
    const nextId = existingCount + 1;

    orderCart.forEach((element) => {
      const {
        cpf, date, dateFormat, name, nameOrder, orderSelect, state, userDoc, userUid,
      } = element;

      const data = {
        numberId: nextId,
        cpf,
        date,
        dateFormat,
        name,
        nameOrder,
        orderSelect,
        state,
        prince,
        status: 'pending',
        userDoc,
        userUid,
      };

      dataFormat.push(data);
    });

    try {
      console.log(dataFormat);
      const promises = dataFormat.map((data) => addDoc(collection(db, 'application'), data));
      await Promise.all(promises);

      setOrderCart([]);

      navigate('/myrequest');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DocumentSt>

          <Title text="Pedidos aguardando finalização" url="pending" icon={<FaCartShopping />} />

          {!orderCart && (
            <styles.PendingClear>
              <FaCartShopping />
              <h3>Sem pedidos</h3>

              <span>Realize um pedido, entre e selecione uma das opções e efetue seu pedido</span>

              <Link to="/request">Realize um pedido agora mesmo</Link>
            </styles.PendingClear>
          )}

          {orderCart && (
            orderCart.length <= 0 && (
              <styles.PendingClear>
                <FaCartShopping />
                <h3>Sem pedidos</h3>

                <span>Realize um pedido, entre e selecione uma das opções e efetue seu pedido</span>

                <Link to="/request">Realize um pedido agora mesmo</Link>
              </styles.PendingClear>
            )
          )}

          {orderCart && (
            orderCart.length > 0 && (
              <styles.PendingSt>
                <h3>Pedidos pendentes</h3>

                <span>Para prosseguir com os pedidos efetue o pagamento</span>
              </styles.PendingSt>
            )
          )}

          {orderCart && (
            orderCart.length > 0 && (
            <styles.PendingSend className="box_shadow">

              <styles.PendingSendBuy>
                <Link to="/request">
                  <FaPlus />
                  Continuar comprando
                </Link>
              </styles.PendingSendBuy>

              <span>
                Pedidos totais:
                {' '}
                {orderCart && (
                <b>{orderCart.length}</b>
                )}
              </span>

              <span>
                Valor total:
                {' '}
                <b>
                  R$
                  {prince && (
                    prince.toFixed(2).replace('.', ',')
                  )}
                </b>
              </span>

              <button type="submit" onClick={addOrders}>Finalizar pedido</button>

              <PaymentButton price={1} title="Teste" quantity={1} />

            </styles.PendingSend>
            )

          )}
          {orderCart && (
            orderCart.length > 0 && (
            <styles.PendingTables>
              <thead>
                <tr>
                  <th>Nome do pedido</th>
                  <th>Data</th>
                  <th>Valor</th>
                  <th>Usuário</th>
                  <th>Estado</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {orderCart && (
                  orderCart.map((od) => (
                    <tr>
                      <td>{od.nameOrder}</td>
                      <td>{od.dateFormat}</td>
                      <td>
                        R$
                        {' '}
                        {od.orderSelect.reduce((total, item) => total + parseFloat(item.prince), 0).toFixed(2).replace('.', ',')}
                      </td>
                      <td>
                        <img src={user.img} alt="logo" title={user.name} />
                      </td>
                      <td>{od.state}</td>
                      <td>
                        <div>
                          <button type="button" onClick={() => handleDelete(od.id)}><FaPencil /></button>
                          <button type="button" onClick={() => handleDelete(od.id)}><FaInfo /></button>
                          <button type="button" onClick={() => handleDelete(od.id)}><MdDelete /></button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </styles.PendingTables>
            )
          )}

        </DocumentSt>
      </Center>
    </article>
  );
}
