import React, {
  useEffect,
} from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import Center from '../../components/Center';
import * as styles from './style';
import banner from '../../assets/section.png';
import banner2 from '../../assets/section-2.png';
import Header from '../../components/Header';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import useUser from '../../store/User';

export default function Dashboard() {
  const {
    user, loading, storage,
  } = useUser();

  useEffect(() => {
    console.log(user);
  }, [user]);

  if (loading || !user) {
    return <Loading />;
  }

  if (user) {
    if (!user.access && user.hasOwnProperty('access')) {
      return (
        <article className="article_flex">

          <Header />

          <Center>
            <styles.DashboardSt>

              <Title text="Painel principal" url="dashboard" icon={<AiOutlineHome />} />

              <styles.DashboardAccess>
                <h3>Para conseguir utilizar nossa plataforma entre em contato</h3>

                <styles.DashboardAccessButton>
                  Entrar em contato
                </styles.DashboardAccessButton>

                <h4>Já entrou em contato? Aguarde, em menos de 3 dias úteis já tera acesso a plataforma inteira</h4>
              </styles.DashboardAccess>
            </styles.DashboardSt>
          </Center>
        </article>
      );
    }

    if (user.hasOwnProperty('cnpj')) {
      return (
        <article className="article_flex">

          <Header />
          <Center>
            <styles.DashboardSt>

              <Title text="Painel de controle" url="dashboard" icon={<AiOutlineHome />} />

              <styles.DashboardText $isBanner={banner}>
                <h2>
                  Olá, empresa
                  {' '}
                  {storage.name}
                </h2>

                <styles.DashboardTextInfo>
                  <div>
                    <h3>R$ 0,00</h3>
                    <span>Créditos na plataforma</span>
                  </div>

                  <div>
                    <h3>19</h3>
                    <span>Pedidos pendentes</span>
                  </div>
                </styles.DashboardTextInfo>
              </styles.DashboardText>

              <styles.DashboardRequests className="box_shadow">
                <h2>Pedidos recentes</h2>

                <div>
                  Não há pedidos
                </div>
              </styles.DashboardRequests>
            </styles.DashboardSt>
          </Center>

        </article>
      );
    }

    return (
      <article className="article_flex">

        <Header />
        <Center>
          <styles.DashboardSt>

            <Title text="Painel de controle" url="dashboard" icon={<AiOutlineHome />} />

            <styles.DashboardText $isBanner={banner2}>
              <h2>
                Olá,
                {' '}
                {user.name}
                <img src={user.img} alt="foto usuário" />
              </h2>

              <styles.DashboardTextInfo>
                <div>
                  <h3>R$ 0,00</h3>
                  <span>Créditos na plataforma</span>
                </div>

                <div>
                  <h3>19</h3>
                  <span>Pedidos pendentes</span>
                </div>
              </styles.DashboardTextInfo>
            </styles.DashboardText>

            <styles.DashboardRequests className="box_shadow">
              <h2>Pedidos recentes</h2>

              <div>
                Não há pedidos
              </div>
            </styles.DashboardRequests>
          </styles.DashboardSt>
        </Center>

      </article>
    );
  }

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <styles.DashboardSt>

          <Title text="Painel principal" url="dashboard" icon={<AiOutlineHome />} />

          <styles.DashboardAccess>
            <h3>Algo deu errado!</h3>

            <styles.DashboardAccessButton>
              Recarregar a página
            </styles.DashboardAccessButton>

            <h4>Se o problema persistir, entre em contato</h4>
          </styles.DashboardAccess>
        </styles.DashboardSt>
      </Center>

    </article>
  );
}
